.ReactModal__Content,
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms linear;
}

.ReactModal__Content--after-open,
.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Content--before-close,
.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.ReactModal__Content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .ReactModal__Content:after {
    content: '';
    display: block;
    height: 35vh;
  }
}

.ErrorModal__Content,
.ErrorModal__Overlay {
  opacity: 0;
  transition: opacity 200ms linear;
}

.ErrorModal__Content--after-open,
.ErrorModal__Overlay--after-open {
  opacity: 1;
}

.ErrorModal__Content--before-close,
.ErrorModal__Overlay--before-close {
  opacity: 0;
}

.ErrorModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

.ErrorModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .ErrorModal__Content:after {
    content: '';
    display: block;
    height: 35vh;
  }
}
